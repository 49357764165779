@import "../../variables.css";

.message-card {
    background-color: var(--light-blue);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    color: var(--primary-color);
}

.message {
    align-self: center;
    /*border-left: 1px solid var(--primary-color);*/
    padding-left: 10px;
}

@media (min-width: 992px) {
    .message {
        border-left: 1px solid var(--primary-color);
    }
}

@media (max-width: 992px) {
    .message {
        padding-top: 10px;
    }
}