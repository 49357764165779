@import "../../variables.css";

.collection-card {
    border-radius: 5px;
    border: 1px solid var(--light-grey);
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr max-content;
    grid-template-rows: min-content min-content;
    grid-gap: 20px;
}

.collection-name {
    grid-column: span 2;
    grid-row-start: 1;
}

.collection-qr-code {
    grid-column: span 2;
    grid-row-start: 1;
}
.collection-qr-code > .image-container {
    margin: 0;
}

.collection-progress {
    grid-column: span 3;
    grid-row-start: 2;
    align-self: center;
}

.collection-percentage {
    grid-row-start: 2;
    align-self: center;
}