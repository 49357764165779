@import "../../variables.css";

.gift-card-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.gift-card-container > .gift-card {
    grid-column: span 2;
}

.gift-card-button-holder {
    grid-column: span 4;
    width: 100%;
    display: flex;
}

.gift-card-button-holder button {
    display: flex;
    justify-content: center;
    border: none;
    border-radius: 5px;
    background-color: var(--light-blue);
    padding: 3px;
    width: 10%;
    margin-right: 10px;
}

.button-active-currency {
    box-shadow: 0 0 4px 0 var(--light-grey);
    background-color: var(--primary-color) !important;
    color: var(--font-color);
}
