@import "../../variables.css";

.visited-card-href {
    text-decoration: none;
    color: var(--primary-color);
}

.visited-card {
    box-sizing: border-box;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    transition: 0.3s;
    display: grid;
    grid-template-rows: auto auto;
    overflow: hidden;
    justify-content: center;
}

.visited-card:hover {
    box-shadow: 0 0 10px 5px var(--light-grey);
}

.card-image {
    width: 100%;
    background-color: #efefef;
}

.card-text {
    text-align: center;
    padding: 10px;
    border-top: 1px solid var(--light-grey);
}

.card-image > img {
    height: 140px;
}

.card-text:hover {
    color: var(--primary-color);
}
