@import "../../variables.css";

.gift-card {
    border-radius: 5px;
    border: 1px solid var(--light-grey);
    padding: 20px;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gift-card-name, .gift-card-price {
    text-align: center;
}

.gift-card-price {
    margin: 10px 0 0 0;
    font-weight: bold;
}