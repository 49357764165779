@import "variables.css";
@import "bootstrap/dist/css/bootstrap.min.css";

.app-header {
  display: flex;
  color: var(--primary-color);
  min-height: 75px;
}

@media (min-width: 768px) {
  .app-header {
    min-height: 125px;
  }
}

@media (min-width: 992px) {
  .app-header {
    min-height: 110px;
  }
}

.row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.col-sm-12 {
  padding-bottom: 20px;
}