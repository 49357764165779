@import "../../variables.css";

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid black;
    background-color: white;
    z-index: 100;
}

.navigation {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.link {
    padding-right: 30px !important;
    margin: 17px 10px;
    text-decoration: none;
    border-right: 1px solid var(--light-grey);
}

.link.active {
    border-right: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.link:hover {
    text-decoration: underline;
}

.header-column {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .header-column {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        padding: 8px 0;
    }
}
