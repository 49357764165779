@import "../../variables.css";

.note {
    font-size: 12px;
}

.form-input {
    border-radius: 5px;
    margin: 10px 0;
    width: 100%;
    min-height: 80px;
    max-height: 200px;
    border: 1px solid var(--dark-grey);
}

.regenerate-qr-code-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: var(--font-color);
    border-radius: 5px;
    border: 0;
    margin: 0 0 30px 0;
}

.send-us-money-form {
    background-color: var(--light-blue);
    padding: 20px;
    border-radius: 5px;
}